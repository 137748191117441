import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import InputField from '../components/form/InputField'
import FormWrapper from '../components/form/FormWrapper'
import useFormState from '../components/form/hooks/useFormState'
import HaveQuestions from '../components/StillHaveQuestions'
import CustomerRepairLetters from '../components/repairs/CustomerRepairLetters'
import isEmail from '../components/form/validators/isEmail'
import isPhone from '../components/form/validators/isPhone'

const EZShipBox = () => {
  const [form, , updateForm] = useFormState({
    name: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    item_type: '',
    zip: '',
  })
  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo
        title="Request an Easy Ship Box for Watch or Jewelry Repairs"
        description="Fill out the form to get a pre-labeled Easy Ship Box with instructions and packing materials for your watch repair. Receive your box in 3-4 business days."
        canonical={'/easy-ship-box-repairs/'}
      />

      <section className="w-10/12 max-w-[1366px] mx-auto py-10">
        <h1 className="text-center underline-title text-xl uppercase my-5">
          Request an Easy Ship Box for Watch or Jewelry Repairs
        </h1>
        <p className="text-center">
          Fill out the form below, and we will send you a pre-labeled Easy Ship Box with instructions 
          & packing materials to send in your watch for a repair estimate. 
          You should receive your box within 3-4 business days.
        </p>
        <div className="w-full flex flex-col xl:flex-row gap-5 py-5">
          <div className="w-full flex justify-start aspect-video xl:aspect-square">
            <StaticImage
              src={'../images/ez-ship-box-form-header.png'}
              alt={'EZ Ship Box Supplies Provided'}
              className={'object-contain'}
              loading={'eager'}
            />
          </div>
          <div className="flex w-full flex-col">
            <FormWrapper className={''} name={'EZ Ship Box Repairs'} action={'/thank-you-ez-ship-box/'} form={form}>
              <div className="flex flex-col space-y-4">
                <InputField
                  name={'name'}
                  label={'Name'}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={'phone'}
                  label={'Phone'}
                  form={form}
                  validator={isPhone}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={'email'}
                  label={'E-Mail'}
                  form={form}
                  type={'email'}
                  validator={isEmail}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={'address1'}
                  label={'Street Address'}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={'address2'}
                  label={'Address Line 2'}
                  form={form}
                  onChange={updateForm}
                />
                <span className="grid grid-cols-4 gap-4">
                  <InputField
                    name={'city'}
                    label={'City'}
                    form={form}
                    onChange={updateForm}
                    className={'col-span-4 sm:col-span-2 lg:col-span-3'}
                    required={true}
                  />
                  <InputField
                    name={'state'}
                    label={'State'}
                    form={form}
                    onChange={updateForm}
                    className={'sm:col-span-2 lg:col-span-1 col-span-4'}
                    required={true}
                  />
                </span>
                <InputField
                  name={'item_type'}
                  label={'What are you looking to repair?'}
                  form={form}
                  onChange={updateForm}
                />
              </div>
              <br />
              <br />
              <button className="bg-red-700 text-white px-8 py-3 block mx-auto lg:mx-0">
                SUBMIT FORM
              </button>
            </FormWrapper>
          </div>
        </div>
      </section>

      <CustomerRepairLetters />
      <HaveQuestions />
      <section className={'mt-16'}>
        <Map />
      </section>
    </Layout>
  )
}
EZShipBox.propTypes = {
  location: PropTypes.object,
}

export default EZShipBox
